export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 87">
      <g fill={fillColor}>
        <g>
          <g>
            <g>
              <path d="M57.3,12.4c1.6-2.4,0.5-4.8-0.9-7h-0.2V5.2L56.1,5C55.4,3.9,55,3.2,54,2.1C53.3,1,51.4,0,50.1,0      c-0.1,0-0.2,0-0.4,0c-3.8,0-6,4-6.6,5.2l0,0.1H43c-0.9,1.8-2.2,4.5-1.3,6.8c1.2,2.4,4.4,2.7,7.2,2.8c0.6-0.1,1.2-0.1,1.8-0.2      C53.3,14.5,55.9,14.3,57.3,12.4z" />
              <path d="M66.2,24.2c-0.1-0.2-0.2-0.5-0.3-0.8l-0.1-0.2h0c-0.3-0.5-0.6-1.2-1-1.9c-0.2-0.4-0.4-0.7-0.6-1.1l-0.1,0.1      L64.1,20c-1.6-2.3-3.1-3.7-4.7-4.2c-1.3-0.3-2.8-0.7-4.2-0.7c-1.8,0-3.2,0.5-4.2,1.7c-1.1,2.2-1.3,5.1-0.8,7.7      c0.3,1.6,1.2,4.3,3.2,4.8l0,0c1.3,0.8,2.9,0.9,4.1,1l0.1,0c0.2,0,0.3,0,0.5,0c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0,0.8,0      c2.4,0,4.9-0.3,6.3-2.4C66.9,26.3,66.6,25.5,66.2,24.2z" />
              <path d="M49.1,20c-0.1-1.3-0.3-2.7-1.1-3.6c-0.7-0.9-2-1.3-3.7-1.3c-1.3,0-2.6,0.2-3.5,0.4c-3.8,1-6.5,5.4-7.7,8.9      c0,0.2-0.1,0.5-0.3,0.9c0,0.2,0,0.5-0.1,0.7c-0.1,0.9-0.2,1.9,0.7,2.7c1.6,1.2,3.3,1.7,5.6,1.7c1.3,0,2.5-0.2,3.6-0.3      c0.3,0,0.5-0.1,0.8-0.1l0.2,0v0.1c0.2-0.1,0.3-0.1,0.5-0.2c1.1-0.3,2.1-0.6,3-1.4c1.8-2.4,1.9-4.3,2.1-6.7      c0-0.4,0.1-0.9,0.1-1.3l-0.1-0.1L49.1,20z" />
            </g>
            <g>
              <path d="M90.1,49.9l-0.1-0.4c0.2-0.3,0.1-1.4,0.3-1.9c0.1-0.5,0.1-0.7,0.4-1.1c1,0.7,2.1,2.1,3.5,1.9      c0.6-0.1,1.2-0.2,1.5-0.6c0.4-0.5,0.3-1.3,0.3-1.7c-0.9-1.6-3.9-1-4.7-2.3c-0.3-0.4-0.7-1-0.9-1.6h0.1c-0.1-0.3-0.2-0.4-0.2-0.7      c-0.1-1.5,0.3-2.6,1.4-3.3c2-1.4,4.9-0.8,7-0.9c0.9,0.7,0.4,2.8,0.5,3.9c-0.4-0.1-1-0.8-1.4-1c-0.9-0.6-1.8-0.5-2.7-0.2      c-0.3,0.1-0.8,0.5-0.9,0.9c-0.2,0.5,0.2,0.9,0.4,1.3c1.1,0.7,3.2,1.2,3.9,1.7c1.1,0.9,1.4,2,1.6,3c-0.1,0.8-0.5,2.1-1.2,2.7      c-0.5,1.2-2.9,1.8-4.4,1.9C92.9,51.6,91.3,51.1,90.1,49.9z" />
              <path d="M47,37.3c-0.2,0.6-1,1.4-0.9,2L46,39.2c-1.1,2.7-1.4,5.4-0.3,8.5c0.7,0.7,1.8,1,2.8,0.9c1,0,2.4-1.1,3.1-2      c0.2,0.6-0.4,1.8-0.5,2.4c-0.4,0.7-0.5,2-1.4,2.2l-6.5,0.1c-1.6-0.3-1.2-2.5-1.4-3.5c-0.4-3.5,0.3-7.2,1.8-10.2      C44.5,37,45.8,36.9,47,37.3z" />
              <path d="M39.3,37.5c1,2.6,1.1,4.4,1.4,7c0.1,1.5-0.6,4.6-2.2,5.4c-2.9,1.7-5.3,1.8-9.1,0.4c-1.3-0.6-2.5-2-2.5-3      c-0.3-1.6-0.1-2.2-0.1-3.8h0.1c0.1-1.8,0.8-3.8,1.3-5.6c0.5-1.2,2.6-0.5,3.6-0.7l0.2,0.3c-2,2.2-2.2,5.3-2.1,7.6      c0.2,1.3,1.6,2.4,2.6,2.7c1.3,0.3,3.1,0.4,4-1.1c1.6-2,1.4-5.4,0.4-8.2c-0.1-0.4-0.9-0.8-0.8-1.3c0.8-0.2,1.9,0,2.9,0L39.3,37.5      z" />
              <path d="M75.1,37.3c0.7,0.4,0.8,1.4,0.8,2c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.5,0.1,0.8c-1.2-0.7-3-1.7-4.7-1      c-0.8,0.1-1.6,0.9-1.9,1.6c0,0.3-0.7,1.3,0,1.4c1.4-1.1,3.5-1.7,5.3-0.7c0.9,0.5,0.4,1.6,0.7,2.2c0,0.5,0.1,0.7-0.1,1.2      c-0.5-0.2-1.1-0.7-1.6-0.9c-1.4-0.7-3-0.1-3.9,0.7c-0.7,0.7-0.9,1.4-0.7,2.2c0.2,1,1.4,1.5,2.3,1.7c1.6,0.5,3.3-0.7,4.4-2      c0.1-0.1,0.3,0.1,0.2,0.2c-0.3,1.4-0.7,2.6-1.5,4c-0.5,0.1-1,0.1-1.7,0.1v0.1L66.2,51c-0.7-0.5-0.5-1.1-0.5-2      c0-2.6,0-3.9,0.2-6.2c0.3-1.1,0.1-2,0.6-3c0.2-0.7,0.3-2.4,1.3-2.6C69.7,37.3,72.5,37.1,75.1,37.3z" />
              <path d="M64.3,44.6c-0.1-0.8-0.3-1.8-0.8-2.8c-1.6-3.2-5.6-4.7-9.4-4.7c-0.5-0.1-0.6,0.5-0.7,0.7      c-0.7,4-1.2,7.1-0.9,11.4c0,0.7,0,1.6,0.4,1.8c2.9-0.1,6.6,0.7,10-1.9c0.6-0.5,1.2-1.8,1.5-2.5C64.3,46,64.6,45.3,64.3,44.6z       M60.3,47.2L60.3,47.2c-0.4,0.9-1.9,0.9-2.9,0.9c-1.6-1.2-1.8-2.5-1.7-4.1c-0.1-1.4-0.1-2.6,0.4-4.1c1.3-0.5,2,0.5,3.1,1.2      c0.9,0.8,1.3,1.9,1.6,2.7C61.1,44.9,61,46.2,60.3,47.2z" />
              <path d="M85.5,45.5c-0.3-0.2-1.1-0.4-0.8-0.9l1.6-0.9c1.5-1.4,1.5-2.6,1-3.9l-0.4-0.7c-2.4-2.6-5.6-1.8-8.6-1.8      L78,37.7c-0.6,3.8-1.1,7.6-1,11.4c0.1,0.9,0,1.6,0.5,2c1.1,0.2,2.7,0.2,3.6,0.1c0.4-0.1-0.1-0.9-0.3-1.6c-0.1-1-0.5-2.4,0-3.5      c0.6-0.3,1.3-0.1,2,0.1c1.2,0.6,2.2,2,2.6,3.2c0.1,0.7,0.1,1.1,0.3,1.6c1.2,0.3,2.2,0.1,3.7,0.1l0.1-0.2      C88.9,48.9,87.4,46.5,85.5,45.5z M83.6,41.4c-0.1,0.8-1.4,1.8-2.1,2.1c-0.3,0.1-0.3,0.3-0.8,0.1c-0.2-0.9-0.1-2.8,0.9-3.7      c0.7-0.2,1.2-0.4,1.8,0.2C83.7,40.6,83.6,40.9,83.6,41.4z" />
              <path d="M25.9,44.9c-0.1-0.2-0.2-0.5-0.1-0.7c-0.3-0.4-0.3-1.1-0.5-1.5c-1.4-2.4-2.2-3.9-4.6-5.4h0.1      c-0.8-0.5-1.6-0.4-2.6-0.4c-2.8,0.4-5.1,4.1-6,6.4c-0.6,1.4-1,2.8-0.7,4.4c0.8,1.6,2.4,3.1,4.3,3.5c3.4,0.5,6.8,0.1,8.7-1.4      C25.8,48.5,26.1,46,25.9,44.9z M21.6,47.5c-0.1,0.3-0.9,0.8-1.2,0.9c-1.4,0-2.8,0.5-4.3-0.3c-1.7-2.2-1.1-4.4,0.4-6.8      c0.4-0.5,1.1-1.2,1.8-1.4c2.3,0.2,3.3,2.3,4,4.2C22.3,45.3,22.5,46.3,21.6,47.5z" />
              <path d="M10.4,46.7C10,45.4,9,44.9,7.5,44.1c-0.1-0.4,0.5-0.6,0.8-1c1.4-1.2,1.4-2.8,0.9-4.1      c-1.4-1.8-3.3-2.1-5.8-2c-0.7,0.3-1.6-0.1-2.3,0.4c-0.2,0.3-0.3,0.5-0.3,0.8l-0.1,0.3H0.6C0.2,41.8,0,45.1,0,48.6      c0.3,1-0.1,1.8,0.7,2.4h6c0.6,0,1.4-0.4,1.9-0.5C10.1,49.8,10.6,48.3,10.4,46.7z M3.8,40.3c0.7-0.7,2-1.2,2.5,0.1      c-0.1,0.2,0,0.5-0.3,0.7c0,0.3-0.3,0.7-0.5,0.9c-1,0.7-1.1,1.1-2.2,1.1C3,42.3,3.2,41.1,3.8,40.3z M6.2,48.7      c-0.5,0.2-0.8,0.4-1.3,0.3c-1.2-0.5-1.2-1.4-1.6-2.4c0.1-0.3-0.1-0.5,0.4-0.7c0.9,0,2.3,0.6,2.6,1.3C6.5,47.7,6.4,48.3,6.2,48.7      z" />
            </g>
            <g>
              <path d="M14.1,57.7h1.8c1,0,1.6,0.6,1.6,1.5c0,0.7-0.4,1.3-1.2,1.5l1.5,2.1h-0.6l-1.4-2h-1v2h-0.6V57.7z M15.8,60.2      c0.8,0,1.2-0.3,1.2-1c0-0.7-0.5-1-1.2-1h-1.1v2.1H15.8z" />
              <path d="M21.4,57.7h3.2v0.5h-2.7v1.7h2.2v0.5h-2.2v1.8h2.7v0.5h-3.3V57.7z" />
              <path d="M27.9,61.6l0.5-0.2c0.2,0.6,0.7,0.9,1.4,0.9c0.7,0,1.2-0.3,1.2-0.9c0-0.5-0.3-0.8-0.8-0.9l-1-0.3      c-0.7-0.2-1.1-0.6-1.1-1.3c0-0.8,0.7-1.4,1.6-1.4c0.8,0,1.4,0.3,1.7,1L31,58.7c-0.2-0.4-0.6-0.7-1.2-0.7c-0.5,0-1,0.3-1,0.8      c0,0.4,0.2,0.7,0.8,0.8l1,0.3c0.6,0.2,1,0.5,1,1.3c0,0.9-0.7,1.5-1.8,1.5C28.8,62.8,28.1,62.4,27.9,61.6z" />
              <path d="M35.1,61.1v-1.7c0-1.1,0.9-1.8,1.9-1.8c1.1,0,1.9,0.6,1.9,1.7V61c0,1.1-0.9,1.8-1.9,1.8      C36,62.8,35.1,62.2,35.1,61.1z M38.4,61v-1.7c0-0.8-0.6-1.2-1.4-1.2c-0.7,0-1.3,0.5-1.3,1.3V61c0,0.8,0.6,1.2,1.4,1.2      C37.8,62.3,38.4,61.8,38.4,61z" />
              <path d="M42.8,57.7h1.8c1,0,1.6,0.6,1.6,1.5c0,0.7-0.4,1.3-1.2,1.5l1.5,2.1h-0.6l-1.4-2h-1v2h-0.6V57.7z M44.5,60.2      c0.8,0,1.2-0.3,1.2-1c0-0.7-0.5-1-1.2-1h-1.1v2.1H44.5z" />
              <path d="M51.2,58.1h-1.6v-0.5h3.8v0.5h-1.6v4.6h-0.6V58.1z" />
              <path d="M58.3,61.4c0-0.6,0.3-1.2,1.3-1.5c-0.4-0.4-0.6-0.8-0.6-1.2c0-0.5,0.3-1.2,1.5-1.2c0.8,0,1.3,0.5,1.3,1.1      c0,0.7-0.6,1.2-1.3,1.4l1.2,1.3c0.1-0.3,0.3-0.6,0.4-0.9l0.5,0.1c-0.1,0.4-0.3,0.8-0.5,1.2l0.9,1h-0.7l-0.6-0.6      c-0.4,0.4-1,0.7-1.7,0.7C58.9,62.8,58.3,62.2,58.3,61.4z M61.3,61.8l-1.4-1.6c-0.7,0.2-1,0.7-1,1.1c0,0.6,0.5,1,1.1,1      C60.5,62.3,61,62.1,61.3,61.8z M61.2,58.6c0-0.5-0.4-0.7-0.8-0.7c-0.6,0-0.9,0.3-0.9,0.7c0,0.3,0.2,0.6,0.4,0.8      c0.1,0.1,0.1,0.1,0.2,0.2C60.8,59.5,61.2,59.2,61.2,58.6z" />
              <path d="M68.4,61.6l0.5-0.2c0.2,0.6,0.7,0.9,1.4,0.9c0.7,0,1.2-0.3,1.2-0.9c0-0.5-0.3-0.8-0.8-0.9l-1-0.3      c-0.7-0.2-1.1-0.6-1.1-1.3c0-0.8,0.7-1.4,1.6-1.4c0.8,0,1.4,0.3,1.7,1l-0.5,0.2c-0.2-0.4-0.6-0.7-1.2-0.7c-0.5,0-1,0.3-1,0.8      c0,0.4,0.2,0.7,0.8,0.8l1,0.3c0.6,0.2,1,0.5,1,1.3c0,0.9-0.7,1.5-1.8,1.5C69.3,62.8,68.6,62.4,68.4,61.6z" />
              <path d="M75.7,57.7h1.8c1,0,1.6,0.6,1.6,1.5c0,0.8-0.6,1.5-1.7,1.5h-1.2v2h-0.6V57.7z M77.3,60.2      c0.8,0,1.3-0.3,1.3-1c0-0.7-0.5-1-1.1-1h-1.2v2.1H77.3z" />
              <path d="M83.9,57.6h0.5l1.9,5.1h-0.6l-0.5-1.5h-2.1l-0.5,1.5H82L83.9,57.6z M85,60.8l-0.9-2.4h0l-0.9,2.4H85z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M18.1,77.2L18.1,77.2c0-1.1,0.8-2,2-2c0.7,0,1.2,0.2,1.5,0.6l-0.5,0.6c-0.3-0.3-0.6-0.4-1-0.4      c-0.7,0-1.1,0.5-1.1,1.2v0c0,0.7,0.5,1.2,1.1,1.2c0.4,0,0.7-0.2,1-0.5l0.5,0.5c-0.4,0.4-0.8,0.7-1.6,0.7      C18.9,79.2,18.1,78.3,18.1,77.2" />
              <path d="M22.7,77.5v-2.2h0.9v2.2c0,0.6,0.3,1,0.8,1c0.5,0,0.8-0.3,0.8-0.9v-2.2h0.9v2.2c0,1.2-0.7,1.7-1.7,1.7      C23.3,79.2,22.7,78.6,22.7,77.5" />
              <path d="M29.1,77.1c0.4,0,0.7-0.2,0.7-0.5v0c0-0.4-0.3-0.6-0.7-0.6h-0.8v1.1H29.1z M27.4,75.3h1.8      c0.5,0,0.9,0.1,1.1,0.4c0.2,0.2,0.3,0.5,0.3,0.9v0c0,0.6-0.3,1-0.8,1.2l0.9,1.4h-1l-0.8-1.2h-0.7v1.2h-0.9V75.3z" />
              <rect x="31.8" y="75.3" width="0.9" height="3.9" />
              <path d="M37.1,77.2L37.1,77.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2v0c0,0.7,0.5,1.2,1.2,1.2      S37.1,77.9,37.1,77.2 M33.9,77.2L33.9,77.2c0-1.1,0.9-2,2.1-2c1.2,0,2.1,0.9,2.1,2v0c0,1.1-0.9,2-2.1,2      C34.7,79.2,33.9,78.3,33.9,77.2" />
              <path d="M41.1,77.2L41.1,77.2c0-1.1,0.8-2,2-2c0.7,0,1.2,0.2,1.5,0.6l-0.5,0.6c-0.3-0.3-0.6-0.4-1-0.4      c-0.7,0-1.1,0.5-1.1,1.2v0c0,0.7,0.5,1.2,1.1,1.2c0.4,0,0.7-0.2,1-0.5l0.5,0.5c-0.4,0.4-0.8,0.7-1.6,0.7      C41.9,79.2,41.1,78.3,41.1,77.2" />
              <path d="M48.7,77.2L48.7,77.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2v0c0,0.7,0.5,1.2,1.2,1.2      C48.2,78.4,48.7,77.9,48.7,77.2 M45.5,77.2L45.5,77.2c0-1.1,0.9-2,2.1-2c1.2,0,2.1,0.9,2.1,2v0c0,1.1-0.9,2-2.1,2      C46.3,79.2,45.5,78.3,45.5,77.2" />
              <polygon points="50.8,75.3 51.6,75.3 51.6,78.4 53.5,78.4 53.5,79.1 50.8,79.1     " />
              <polygon points="54.6,75.3 55.5,75.3 55.5,78.4 57.4,78.4 57.4,79.1 54.6,79.1     " />
              <polygon points="58.4,75.3 61.4,75.3 61.4,76 59.3,76 59.3,76.8 61.1,76.8 61.1,77.6 59.3,77.6 59.3,78.4       61.4,78.4 61.4,79.1 58.4,79.1     " />
              <path d="M62.4,77.2L62.4,77.2c0-1.1,0.8-2,2-2c0.7,0,1.2,0.2,1.5,0.6l-0.5,0.6c-0.3-0.3-0.6-0.4-1-0.4      c-0.7,0-1.1,0.5-1.1,1.2v0c0,0.7,0.5,1.2,1.1,1.2c0.4,0,0.7-0.2,1-0.5l0.5,0.5c-0.4,0.4-0.8,0.7-1.6,0.7      C63.2,79.2,62.4,78.3,62.4,77.2" />
              <polygon points="68,76 66.8,76 66.8,75.3 70,75.3 70,76 68.8,76 68.8,79.1 68,79.1     " />
              <rect x="71.1" y="75.3" width="0.9" height="3.9" />
              <path d="M76.4,77.2L76.4,77.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2v0c0,0.7,0.5,1.2,1.2,1.2      C75.9,78.4,76.4,77.9,76.4,77.2 M73.2,77.2L73.2,77.2c0-1.1,0.9-2,2.1-2c1.2,0,2.1,0.9,2.1,2v0c0,1.1-0.9,2-2.1,2      C74,79.2,73.2,78.3,73.2,77.2" />
              <polygon points="78.5,75.3 79.3,75.3 81.1,77.6 81.1,75.3 81.9,75.3 81.9,79.1 81.2,79.1 79.3,76.7 79.3,79.1       78.5,79.1     " />
              <path d="M60.2,84.9l0.2,0v0.2h-1.2v-0.2l0.2,0v-1.5c0-0.4-0.2-0.6-0.5-0.6c-0.1,0-0.4,0.1-0.5,0.2v1.9l0.2,0v0.2      h-1.2v-0.2l0.2,0v-2.2l-0.2,0v-0.2h0.9v0.4c0.3-0.2,0.6-0.4,0.9-0.4c0.5,0,0.7,0.3,0.7,0.9V84.9z M55.8,84.9      c-0.4,0-0.6-0.3-0.6-1.2c0-0.8,0.2-1.2,0.6-1.2c0.4,0,0.6,0.4,0.6,1.2C56.4,84.6,56.2,84.9,55.8,84.9 M55.8,82.4      c-0.9,0-1.4,0.6-1.4,1.4c0,1,0.5,1.4,1.4,1.4c0.9,0,1.4-0.6,1.4-1.4C57.2,82.9,56.6,82.4,55.8,82.4 M53.9,84.8      c0.1,0,0.3,0,0.4,0v0.2c-0.3,0.1-0.6,0.2-0.8,0.2c-0.5,0-0.7-0.2-0.7-0.7v-1.7h-0.5v-0.2h0.5v-0.5l0.7-0.2v0.8h0.6v0.2h-0.6v1.6      C53.5,84.7,53.5,84.8,53.9,84.8 M51.8,84.9l0.2,0v0.2h-1.2v-0.2l0.2,0v-3.4l-0.2,0v-0.2h0.9V84.9z M50.2,84.9l0.2,0v0.2h-1.2      v-0.2l0.2,0v-2.2l-0.2,0v-0.2h0.9V84.9z M49.5,81.6c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4      C49.6,82,49.5,81.9,49.5,81.6 M47.5,81.4h1.3v0.2l-0.3,0v3.3l0.3,0v0.2h-1.3v-0.2l0.3,0v-1.5h-1.6v1.5l0.2,0v0.2h-1.2v-0.2      l0.2,0v-3.3l-0.2,0v-0.2h1.2v0.2l-0.2,0V83h1.6v-1.4l-0.3,0V81.4z M43.3,82.4h0.8v0.2l-0.2,0l-1.1,2.6c-0.2,0.6-0.5,1.1-1,1.1      c-0.2,0-0.3,0-0.4-0.1v-0.6h0.2l0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.3,0,0.5-0.5,0.7-0.8l0-0.1l-1.1-2.5l-0.2,0v-0.2h1.2v0.2      l-0.2,0l0.7,1.6l0.6-1.6l-0.2,0V82.4z M39.9,84.9c-0.3,0-0.4-0.1-0.6-0.2V83c0.2-0.1,0.3-0.1,0.5-0.1c0.4,0,0.7,0.4,0.7,1      C40.5,84.5,40.3,84.9,39.9,84.9 M40.2,82.4c-0.3,0-0.5,0.1-0.8,0.4v-1.5h-0.9v0.2l0.2,0v3.4l-0.2,0v0.2h0.9v-0.1      c0.1,0.1,0.3,0.2,0.7,0.2c0.8,0,1.3-0.5,1.3-1.4C41.2,82.9,40.8,82.4,40.2,82.4" />
              <rect x="15.8" y="68.7" width="68.4" height="0.7" />
            </g>
          </g>
        </g>
        <polygon points="62,81.5 62,82.1 62.1,82.1 62.1,82.2 61.8,82.2 61.8,82.1 61.9,82.1 61.9,81.5 61.6,82.2 61.5,82.2    61.3,81.5 61.3,82.1 61.3,82.1 61.3,82.2 61.1,82.2 61.1,82.1 61.2,82.1 61.2,81.5 61.1,81.4 61.1,81.4 61.4,81.4 61.6,81.9    61.8,81.4 62.1,81.4 62.1,81.4  " />
        <polygon points="60.3,81.4 61.1,81.4 61.1,81.5 61,81.5 61,81.5 60.8,81.5 60.8,82.1 60.9,82.1 60.9,82.2 60.5,82.2    60.5,82.1 60.6,82.1 60.6,81.5 60.4,81.5 60.4,81.5 60.3,81.5  " />
      </g>
    </svg>
  );
}
